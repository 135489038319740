import { Button, Container } from 'react-bootstrap'
import { GetStaticProps, NextPage } from 'next'

import { AppCommonProps } from 'types/app'
import Head from 'next/head'
import Link from 'next/link'
import React, { useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { withI18n } from '~/hocs'
import { routes } from '~/router'

const Page404: NextPage<AppCommonProps> = ({ t, lng }) => {
  const router = useRouter()

  useEffect(() => {
    router.push(routes.home.href)
  }, [])

  return (
    <>
      <Head>
        <title>Error 404 Not Found</title>
      </Head>
      {/* <Container className="text-center">
        <img src="https://www.photo-ac.com/assets/img/404.png" alt="" />
        <h3>{t('error_page:404_title')}</h3>
        <h5>{t('error_page:404_description')}</h5>
        <Button onClick={() => router.back()} className="mr-3"><i className="fas fa-long-arrow-alt-left" /> {t('back_to_previous_page')}</Button>
        <Button variant="outline-primary"><Link href="/" locale={lng}><a>{t('home')}</a></Link></Button>
      </Container> */}
    </>
  )
}

export const getStaticProps: GetStaticProps = async(ctx: any) => {
  // return {
  //   redirect: {
  //     destination: '/',
  //     permanent: false,
  //   },
  // }
  const { locale } = ctx
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'error_page'])),
    },
  }
}

export default withI18n(Page404)
